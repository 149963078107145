// BottomTabBar.js
import React, { useState } from 'react';
import {
  CalendarOutlined,
  ExclamationCircleOutlined,
  LogoutOutlined,
  QrcodeOutlined,
  SettingOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { NavLink, useNavigate } from 'react-router-dom';
import { message } from 'antd/lib';
import { postRequestApi } from '../../../../api/commonRequest';
import { actionForUserLogout } from '../../../utility';
import Modal from 'antd/lib/modal/Modal';
import Typography from 'antd/lib/typography/Typography';

const BottomTabBar = () => {
  let navigate = useNavigate();
  const startLoadTheMessage = () => message.loading({ content: 'Loading...', key: 'load', duration: 0 })
  const stopLoadingMsh =(msg) => message.error({ content: msg, key: 'load', duration: 2, })
  const stopLoadingSuccessMsh =(msg) => message.success({ content: msg, key: 'load', duration: 2, })

  const [selectedItem, setSelectedItem] = useState('scan');

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const handleLogout = () => {
    Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: <>
            <Typography.Text>Are you sure? You want to logout?</Typography.Text>
        </>,
        onOk() {
          logOutUser();
        }
    });
  }

  const logOutUser = async () => {
    startLoadTheMessage()
    const lo_response = await postRequestApi('logout', {})
    if(lo_response.status == "success"){
      console.log('result',lo_response);
      actionForUserLogout();
      navigate('/login');
      stopLoadingMsh('Logged out!!')
    }else{
      message.error('Something Wrong!!')
    }
    actionForUserLogout();
  }

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        background: '#001529', // Add your preferred background color
        color: 'white', // Add your preferred text color
        padding: '10px 0', // Adjust padding based on your design
      }}
    >
      <NavLink
      to={'/scan'}
        style={{
          textAlign: 'center',
          color: selectedItem === 'scan' ? 'cyan' : 'white', // Add a different color for the selected item
        }}
        onClick={() => handleItemClick('scan')}
      >
        <QrcodeOutlined style={{ fontSize: '24px', marginBottom: '8px' }} />
        <div>Scan</div>
      </NavLink>
      <NavLink
       to={'/event'}
        style={{
          textAlign: 'center',
          color: selectedItem === 'events' ? 'cyan' : 'white',
        }}
        onClick={() => handleItemClick('events')}
      >
        <CalendarOutlined style={{ fontSize: '24px', marginBottom: '8px' }} />
        <div>Events</div>
      </NavLink>
      <NavLink
       to={'/guestlist'}
        style={{
          textAlign: 'center',
          color: selectedItem === 'guest-list' ? 'cyan' : 'white',
        }}
        onClick={() => handleItemClick('guest-list')}
      >
        <UnorderedListOutlined style={{ fontSize: '24px', marginBottom: '8px' }} />
        <div>Guest List</div>
      </NavLink>
      <NavLink
        style={{
          textAlign: 'center',
          color: selectedItem === 'logout' ? 'cyan' : 'white',
        }}
        onClick={() => handleLogout()}
      >
        <LogoutOutlined style={{ fontSize: '24px', marginBottom: '8px' }} />
        <div>Logout</div>
      </NavLink>
      {/* <NavLink
       to={'/settings'}
        style={{
          textAlign: 'center',
          color: selectedItem === 'settings' ? 'cyan' : 'white',
        }}
        onClick={() => handleItemClick('settings')}
      >
        <SettingOutlined style={{ fontSize: '24px', marginBottom: '8px' }} />
        <div>Settings</div>
      </NavLink> */}
    </div>
  );
};

export default BottomTabBar;
