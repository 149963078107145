// Home.js
import React, { useEffect, useState } from 'react';
import './style.css'
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Divider, Row, Spin } from 'antd/lib';
import { useDispatch, useSelector } from 'react-redux';
import { getRequestApi } from '../../../api/commonRequest';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import { setSelectedEventDetails, setSelectedEventId } from '../../store/common';
import { convertObjectToArray } from '../../utility';

const Events = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  // const ls_token = useSelector( (state) => state.common.token );
  // const ls_organisationId = useSelector( (state) => state.common.organisation_id );
  const [la_EventsList, setEventsList] = useState([]);
  const [lb_spin, setSping] = useState(true);
  const [li_totalCount, setTotalCount] = useState(true);

  const handleClickEvent = (rowValue) => {
    dispatch(setSelectedEventId(rowValue?.id));
    dispatch(setSelectedEventDetails(rowValue));
    navigate('/scan');
    console.log("rowValue",rowValue);
  }

  const getEventsList = async () => {
    setSping(true)
    // const lo_response = await getRequestApi("all-events/42/all");
    const lo_response = await getRequestApi("ongoing-event-list");
    console.log('evelt list result',lo_response.events);
    // ongoing-event-list
    setSping(false)
    if (lo_response?.events) {
        setEventsList(lo_response.events)
        let count = 0;
        for (let k = 0; k < lo_response?.events?.length; k++) {
          const rowValue = lo_response?.events[k]?.events || {};
            for (const i in rowValue) {
              if (Object.hasOwnProperty.call(rowValue, i)) {
                count += 1;
              }
            }
        }
        setTotalCount(count)
    }else{
        setEventsList([])
    }
  };
  useEffect(() => {
    getEventsList();
  }, []);

  useEffect(() => {
   console.log('---la_EventsList',la_EventsList);
  }, [JSON.stringify(la_EventsList)]);

  return (
    <>
    <div className='firstView'>
      <h2 style={{ color: 'white' }}>Events List</h2>
      <InfoCircleOutlined style={{ fontSize: '36px', color: 'white' }} />
    </div>
    <Spin spinning={lb_spin}>
      <Row>
        <Col span={24}>
          { li_totalCount > 0 ?
            la_EventsList?.map((lo_row) => {
              return (<div>
                <Divider orientation={'left'}>{lo_row.organization_name}</Divider>
                  {
                    convertObjectToArray(lo_row?.events)?.map((lo_eventRow) => {
                      return (<div onClick={() => handleClickEvent(lo_eventRow)} key={lo_eventRow.id}><div className='detailsCard'>
                                <h3 style={{marginTop:5,color:'white'}}>{lo_eventRow.name}</h3>
                                <h5 className='textStyle'>
                                  {lo_eventRow.display_start_time ? ` ${dayjs(lo_eventRow.starts_date, "YYYY-MM-DD").format("dddd, MMMM D, YYYY")} at ${dayjs(lo_eventRow.starts_time, "HH:mm:ss").format("h:mm A")}` : ""}
                                  {lo_eventRow.display_start_time ? ` - ${dayjs(lo_eventRow.ends_date, "YYYY-MM-DD").format("dddd, MMMM D, YYYY")} at ${dayjs(lo_eventRow.ends_time, "HH:mm:ss").format("h:mm A")}` : ""}</h5>
                                <h5 className='textStyle'>{lo_eventRow?.summery}</h5>
                                <p className='textStyle'>{lo_eventRow?.venu_type}</p>
                              </div></div>)
                    })
                  }
              </div>)
            })
            :
            <div className='detailsCard' style={{background:"#ffffff", textAlign:"center"}}>
              <h3 style={{marginTop:5,color:'red'}}>No event available for today, which you can checkin!</h3>
            </div>

          }
        </Col>
      </Row>
    </Spin>
    </>
  );
};

export default Events;

