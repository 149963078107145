import React, { useState } from "react";
import "./style.css";
import logo from "../../../image/logo.png"
import message from "antd/lib/message";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Api_base_url } from "../../../../config";
import { setAllOrganizationList, setLoggingIn, setOrganizationId, setSelectOrganization, setToken, setUser, setUserId, setUserType } from "../../../store/common";
import { Button } from "antd";
const Login = ({lb_isModal=false, actionAfterCompleteModalLogin=()=>{}}) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [isShown, setIsSHown] = useState(false);
  const [lo_responseMessage, setResponseMessage] = useState({})
  const [rememberMe, setRememberMe] = useState(true);
  const [lb_loading, setLoading] = useState(false)

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const handleSubmitLoginData = (e) => {
    e.preventDefault();
    setLoading(true);
    const formdata = new FormData(e.target);
    const jsonData = Object.fromEntries(formdata.entries());
    if (!jsonData.email || !jsonData.password) {
      const errmsg = {};
      if(!jsonData.email) errmsg["email"] = ["Email is required!"];
      if(!jsonData.password) errmsg["password"] = ["Password is required!"];
      console.log('errmsg',errmsg);
      setResponseMessage(errmsg)
      return
    }

    setResponseMessage({})
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch(Api_base_url+"checkin-user-login", requestOptions)
      .then(response => response.text())
      .then(result => {
        setLoading(false)
        const lo_response = JSON.parse(result);
        console.log('lo_response',lo_response);
        if (lo_response.status == 'success') {
          message.success("Successfully Logged in!!");
          setResponseMessage({})
          dispatch(setLoggingIn(true));
          dispatch(setUserType('user'));
          dispatch(setUserId(lo_response.user.id));
          dispatch(setUser(lo_response.user));
          dispatch(setToken(lo_response.token));

          getAndsetAllOrganizationList(lo_response.token)
        }else {
          console.log('-----err',lo_response.message[0]);
          setResponseMessage(lo_response.message)
          message.error(lo_response.message[0])
        }
      })
      .catch(error => {
        setLoading(false)
        console.log('error', error);
        setResponseMessage(['All Credentials required / Something Wrong!!'])
        message.error('All Credentials required / Something Wrong!!')
      });
  }

  // should make it comon
  const getAndsetAllOrganizationList = (ls_token) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+ls_token);
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

    fetch(Api_base_url+"all-organisations", requestOptions)
    .then(response => response.text())
    .then(async (result) => {
        const lo_response = JSON.parse(result)
        if (lo_response?.organisations) {
          dispatch(setAllOrganizationList(lo_response.organisations));
          if(lo_response.organisations.length == 1){
            const lo_orgDetails = {...lo_response.organisations[0]};
            dispatch(setOrganizationId(lo_orgDetails.id));
            dispatch(setSelectOrganization(lo_orgDetails));
          }
        }
        if (lb_isModal) {
          actionAfterCompleteModalLogin()
        }else{
          navigate('/home')
        }
    })
    .catch(error => console.log('error', error));
  }
  
  return (
    <>
      <form onSubmit={handleSubmitLoginData} action="#" className="sign-in-form">
        <div className="login-header-block">
          <Link to={"/home"}><img src={logo} /></Link>
          <h2 className="title">Good to see you again</h2>
        </div>
        
        <div className={`input-field ${Object.keys(lo_responseMessage).length > 0 ? 'input-err-border' : ''}`}>
          <i className="fa-solid fa-user"></i>
          <input type="text"  name="email" placeholder="Username" />
          {lo_responseMessage['email'] ? <p className="comn-input-err">{lo_responseMessage['email'][0]}</p> : <></>}
        </div>

        <div className={`input-field ${Object.keys(lo_responseMessage).length > 0 ? 'input-err-border' : ''}`} style={lo_responseMessage['password'] ? {marginBottom:34} : {}}>
          <i className="fas fa-lock"></i>
          <input type={isShown ? "text" : "password"} name="password" placeholder="Password" />
          <button type="button" className="button login-show-hide-button" onClick={() => setIsSHown((isShown) => !isShown)}>
          {isShown ? <i className="fa-solid fa-eye-slash"></i> : <i className="fa-sharp fa-solid fa-eye"></i>}
          </button>
          {lo_responseMessage['password'] ? <p className="comn-input-err">{lo_responseMessage['password'][0]}</p> : <></>}
        </div>

        <div style={Array.isArray(lo_responseMessage) ? {marginBottom:34} : {}}>
          {
            Array.isArray(lo_responseMessage) ? 
            lo_responseMessage.map((errValue) => <p style={{color:'red'}}>{errValue}</p>)
            : <></>
          }
        </div>

        <div className="form-group">
          <input 
            type="checkbox" id="rememberMe" 
            name="remember-me"
            checked={rememberMe}
            onChange={handleRememberMeChange}
          />
          <label htmlFor="rememberMe">Keep me signed in</label>
        </div>

        {/* <input type="submit" value="Login" className="btn solid" /> */}
        <Button htmlType="submit" type="primary" size="large" className="btn solid" loading={lb_loading}>Login</Button>

      </form>
      
    </>
  );
};

export default Login;