import React from 'react';
import { ConfigProvider, Layout } from 'antd';
// import AppHeader from '../ui/layouts/MainLayout/AppHeader';
import BottomTabBar from '../ui/layouts/MainLayout/BottomTabBar';
import Routers from '../ui/Routers';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from '../ui/store';

const { Content } = Layout;

const App = () => {

  return (
    
    <ConfigProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Routers />
            {/* <BottomTabBar/> */}
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ConfigProvider>
  );
};

export default App;

