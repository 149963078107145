// export const base_url=process.env.WEB_URL || 'https://checkin-prod.d1yanlya8zny51.amplifyapp.com';
export const base_url=process.env.WEB_URL || 'https://checkin.ticketkart.com';
// export const base_url=process.env.WEB_URL || 'http://localhost:3000/';
//for dev
// export const Api_base_url='https://tk.hostmenow.in/tkapp/public/api/';
// export const Admin_base_url='https://tk.hostmenow.in/tkapp';
// export const Print_base_url='https://tk.hostmenow.in/tkapp/public/';

// for production
export const Api_base_url='https://backoffice.ticketkart.com/api/';
export const Admin_base_url='https://backoffice.ticketkart.com/';
export const Print_base_url='https://backoffice.ticketkart.com/';

export const ls_apiKey = "AIzaSyBZkoXNwdRkvP9psrc_mL556abmWOvxdEU";
export const DateFormat = "DD/MM/YYYY";
export const TimeFormat = 'HH:mm';
export const PasswordRules = 
    "Password format rule :  uppercase (A – Z) characters / lowercase characters (a – z) | digits (0 – 9)";
export const MaxTags = 15;
export const MaxTagLength = 25;
export const Live_base_url = 'https://www.ticketkart.com/';
// export const Stripe_pk = 'pk_test_51NB0ZuK1WbW5qDvPcc4m3haWEZo0HgODo6dHRzwW8nk01tFdoUTnBCR2sYcih7rz9LCww8ba0YJxt4lSmxiXusJc00lOSPlN8R';
export const Stripe_pk = 'pk_live_51NB0ZuK1WbW5qDvPsCSVAv2SN1wmLAK9aOEBsnznMfQl2S2fc4t9iwwAzHJ5Wl4hQ4lQvTT1k2Pg1Ep8heGM6lPQ00HWNHmzsI';