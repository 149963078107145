import { createSlice } from '@reduxjs/toolkit';
// import message from 'antd/lib/message';

export const userSlice = createSlice({
    name: 'common',
    initialState: {
        user: null,
        user_id: null,
        organisation_id:null,
        organization_list: [],
        organisation_details: null,
        loggingIn: false,
        userType:null,
        token:null,
        selected_event_id:null,
        selected_event_details:{}
    },
    reducers: {
       
        setSelectOrganization: (state, action) => {
            const lo_organizationDetails = action.payload;
            state.organisation_details = action.payload;
            // if (lo_organizationDetails?.organization_details?.active_status == 'Y') {
            //     state.organisation_details = lo_organizationDetails;
            // }
            // else
            //     message.error("This Organization is not active yet. Please wait untill approve by admin.");
        },
        setOrganizationId: (state, { payload }) => {
            state.organisation_id = payload;
        },
        setAllOrganizationList: (state, action) => {
            state.organization_list = action.payload;
        },
        setUser: (state, { payload }) => {
            state.user = payload;
        },
        setLoggingIn: (state, { payload }) => {
            state.loggingIn = payload;
        },
        setContentView: (state, { payload }) => {
            state.content_view = payload;
        },
        setUserType: (state, { payload }) => {
            state.userType = payload;
        },
        setToken: (state, { payload }) => {
            state.token = payload;
        },
        setUserId: (state, { payload }) => {
            state.user_id = payload;
        },
        setSelectedEventId: (state, { payload }) => {
            state.selected_event_id = payload;
        },
        setSelectedEventDetails: (state, { payload }) => {
            state.selected_event_details = payload;
        },
        logout: (state) => {
            state.user = null;
            state.user_id = null;
            state.organisation_details = null;
            state.organisation_id = null;
            state.organization_list = [];
            state.loggingIn = false;
            state.content_view = null;
            state.userType = null;
            state.token = null;
            state.selected_event_id = null;
            state.selected_event_details = {};
        },

    }
});


// Action creators are generated for each case reducer function
export const { setUserType, setUser, logout, setSelectOrganization, setAllOrganizationList,setLoggingIn,setContentView, setOrganizationId, setToken, setUserId, setSelectedEventId, setSelectedEventDetails} = userSlice.actions;

export default userSlice.reducer;