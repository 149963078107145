import { Outlet } from "react-router-dom"
import BottomTabBar from "./BottomTabBar"

const MainLayout = () => {
    return (<>
        <Outlet />
        <BottomTabBar/>
    </>)
}

export default MainLayout;