import { message } from "antd/lib";
import { Api_base_url, base_url } from "../../config";
import { actionForUserLogout, objectToFormData } from "../../ui/utility";
import store from "../../ui/store";
// console.log('store--',store);

// const ls_token = store.getState( (state) => state.common.token );
// console.log('ls_token>>',ls_token.common.token);
// const ls_organisationId = store.getState( (state) => state.common.organisation_id );

// const ls_token = localStorage.getItem('token')
// const ls_organisationId = localStorage.getItem('organisation_id')

// export const getLink = (ls_targetString) => {
//     const response = createLink(ls_targetString)
//     return response;
// }

// export const createLink = (ls_targetString) => {
//     console.log(ls_targetString);
//     return true;
// }

// export const joinHost = (lo_hostDetals) => {
//     // console.log('joinHost lo_hostDetals',lo_hostDetals);
    
//     sessionStorage.setItem("host_details", JSON.stringify(lo_hostDetals));

//     // let hostDetails = sessionStorage.getItem("host_details");
//     //     console.log('joinHost hostDetails',JSON.parse(hostDetails));
        
//     window.location.href = '/index';
// }


export const getRequestApi = async (ls_apiEndPoint) => {
    const lo_storeData = store.getState();
    const ls_token = lo_storeData.common.token;
    console.log('ls_token>>',ls_token);
    if(!ls_token) return false;
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+ls_token);
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    try {
        console.log('ls_token.....',ls_token, ls_apiEndPoint);
        const response = await fetch(Api_base_url+ls_apiEndPoint, requestOptions)
        const jsonResponse = await response.json();
        console.log('jsonResponse..',jsonResponse);
        if(jsonResponse?.message == 'Unauthenticated.') {
            actionForUserLogout();
            window.location.href = base_url+'/login';
        }
        return jsonResponse;
    } catch (error) {
        console.log('error', error);
        return error;
    }
}

export const postRequestApiByOrganisationId = async (ls_apiEndPoint, lo_formData) => {
    const lo_storeData = store.getState();
    const ls_token = lo_storeData.common.token;
    
    const ls_organisationId = lo_storeData.common.organisation_id;

    if(!ls_token) return false;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+ls_token);
    myHeaders.append("Accept", "application/json");
    const formdata = objectToFormData(lo_formData);
    formdata.append("organisation_id", ls_organisationId);
    // setResponseMessage({})
    let requestOptions = {
        // mode: 'no-cors',
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };
    try {
        const response = await fetch(Api_base_url+ls_apiEndPoint, requestOptions)
        const jsonResponse = await response.json();
        if(jsonResponse?.message == 'Unauthenticated.') {
            actionForUserLogout();
            window.location.href = base_url+'/login';
        }
        return jsonResponse;
    } catch (error) {
        console.log('error', error);
        message.error('Something Wrong!!')
    }
}

export const postRequestApi = async (ls_apiEndPoint, lo_formData) => {
    const lo_storeData = store.getState();
    const ls_token = lo_storeData.common.token;
    console.log('ls_token>>',ls_token);
    if(!ls_token) return false;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+ls_token);
    myHeaders.append("Accept", "application/json");
    const formdata = objectToFormData(lo_formData);
    // setResponseMessage({})
    let requestOptions = {
        // mode: 'no-cors',
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };
    try {
        const response = await fetch(Api_base_url+ls_apiEndPoint, requestOptions)
        const jsonResponse = await response.json();
        if(jsonResponse?.message == 'Unauthenticated.') {
            actionForUserLogout();
            window.location.href = base_url+'/login';
        }
        console.log('===jsonResponse',jsonResponse);
        return jsonResponse;
    } catch (error) {
        console.log('error', error);
        message.error('Something Wrong!!')
    }
}

export const postRequestByTokenParamApi = async (ls_apiEndPoint, lo_formData, ls_tokenId) => {
    if(!ls_tokenId) return false;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+ls_tokenId);
    myHeaders.append("Accept", "application/json");
    const formdata = objectToFormData(lo_formData);
    // setResponseMessage({})
    let requestOptions = {
        // mode: 'no-cors',
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };
    try {
        const response = await fetch(Api_base_url+ls_apiEndPoint, requestOptions)
        const jsonResponse = await response.json();
        if(jsonResponse?.message == 'Unauthenticated.') {
            actionForUserLogout();
            window.location.href = base_url+'/login';
        }
        return jsonResponse;
    } catch (error) {
        console.log('error', error);
        message.error('Something Wrong!!')
    }
}

export const getRequestByTokenParamApi = async (ls_apiEndPoint, ls_tokenId) => {
    if(!ls_tokenId) return false;
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+ls_tokenId);
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    try {
        console.log('ls_tokenId.....',ls_tokenId, ls_apiEndPoint);
        const response = await fetch(Api_base_url+ls_apiEndPoint, requestOptions)
        const jsonResponse = await response.json();
        if(jsonResponse?.message == 'Unauthenticated.') {
            actionForUserLogout();
            window.location.href = base_url+'/login';
        }
        console.log('jsonResponse..',jsonResponse);
        return jsonResponse;
    } catch (error) {
        console.log('error', error);
        return error;
    }
}

export const getPublicRequestApi = async (ls_apiEndPoint) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    try {
        const response = await fetch(Api_base_url+ls_apiEndPoint, requestOptions)
        const jsonResponse = await response.json();
        if(jsonResponse?.message == 'Unauthenticated.') {
            actionForUserLogout();
            window.location.href = base_url+'/login';
        }
        return jsonResponse;
    } catch (error) {
        console.log('error', error);
        return error;
    }
}

export const postRequestBodyRawApi = async (ls_apiEndPoint, lo_formData) => {
    const lo_storeData = store.getState();
    const ls_token = lo_storeData.common.token;
    console.log('ls_token>>',ls_token);
    if(!ls_token) return false;

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer "+ls_token);
    const rawdata = JSON.stringify(lo_formData);
    // setResponseMessage({})
    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: rawdata,
        redirect: 'follow'
    };
    try {
        const response = await fetch(Api_base_url+ls_apiEndPoint, requestOptions)
        const jsonResponse = await response.json();
        if(jsonResponse?.message == 'Unauthenticated.') {
            actionForUserLogout();
            window.location.href = base_url+'/login';
        }
        return jsonResponse;
    } catch (error) {
        console.log('error', error);
        message.error('Something Wrong!!')
    }
}

export const postRequestPublicApi = async (ls_apiEndPoint, lo_formData) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    const formdata = objectToFormData(lo_formData);
    let requestOptions = {
        // mode: 'no-cors',
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };
    try {
        const response = await fetch(Api_base_url+ls_apiEndPoint, requestOptions)
        const jsonResponse = await response.json();
        if(jsonResponse?.message == 'Unauthenticated.') {
            actionForUserLogout();
            window.location.href = base_url+'/login';
        }
        return jsonResponse;
    } catch (error) {
        console.log('error', error);
        message.error('Something Wrong!!')
    }
}