import React, { useEffect } from 'react'
import { BrowserRouter,Navigate,Route, Routes } from "react-router-dom";
import MainLayout from '../layouts/MainLayout';
import Events from '../Pages/Events';
import GuestList from '../Pages/GuestList';
import Settings from '../Pages/Settings';
import Scan from '../Pages/Scan'
import PublicAuth from '../Pages/PublicAuth';
import { useSelector } from 'react-redux';

const Routers = () => {
  const ls_token = useSelector( (state) => state.common.token );
  const ls_organisation_id = useSelector( (state) => state.common.organisation_id );

  useEffect(() => {
    console.log('ls_organisation_id--',ls_organisation_id);
  },[])
  
  return (<>
    { ls_token? 
        <Routes>
          <Route path="/" element={<MainLayout/>} >
            <Route path='/*' element={<Navigate replace to="/home" />} />
            <Route path="/home"  element={<Scan/>}/>
            <Route path="/scan"  element={<Scan/>}/>
            <Route path="/event" element={<Events/>}/>
            <Route path="/guestlist" element={<GuestList/>} />
            <Route path="/settings" element={<Settings/>} />
          </Route>
        </Routes>
      :
        <Routes>
          <Route path='/*' element={<Navigate replace to="/login" />} />
          <Route path="/login" element={<PublicAuth />} />
        </Routes>
    }
  </>)
}

export default Routers