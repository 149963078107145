import React from 'react';
import { Button, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postRequestApi } from '../../../api/commonRequest';
import { actionForUserLogout } from '../../utility';

const startLoadTheMessage = () => message.loading({ content: 'Loading...', key: 'load', duration: 0 })
const stopLoadingMsh =(msg) => message.error({ content: msg, key: 'load', duration: 2, })
const stopLoadingSuccessMsh =(msg) => message.success({ content: msg, key: 'load', duration: 2, })

const Settings = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  
  const ls_token = useSelector( (state) => state.common.token );

  const logOutUser = async () => {
    startLoadTheMessage()
    const lo_response = await postRequestApi('logout', {})
    if(lo_response.status == "success"){
      console.log('result',lo_response);
      actionForUserLogout();
      navigate('/login');
      stopLoadingMsh('Logged out!!')
    }else{
      message.error('Something Wrong!!')
    }
    actionForUserLogout();
  }

  return (
    <div>
      <h2>Settings Page</h2>
      <p>This is the Settings page content.</p>
      <Button onClick={logOutUser}>Logout</Button>
    </div>
  );
};

export default Settings;
