import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './style.css';
import { ArrowRightOutlined, BookOutlined, MailOutlined, SearchOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Button, Col, Empty, Row, Spin } from 'antd/lib';
import HeaderDetails from '../../components/HeaderDetails';
import { getRequestApi } from '../../../api/commonRequest';
import { Link } from 'react-router-dom';
const GuestList = () => {
      const ls_selectedEventId = useSelector( (state) => state.common.selected_event_id );
      const [la_guestsList, setGuestsList] = useState([]);
      const [lb_spin, setSping] = useState(true);

      const getGuestsList = async () => {
            setSping(true)
            const lo_response = await getRequestApi("booking-guest-attened-event/"+ls_selectedEventId);
            // attendee 
            // const lo_response = await getRequestApi("attendees-list/"+ls_selectedEventId);
            console.log('GuestList list result',lo_response);
            // ongoing-Guest-list
            setSping(false)
            if (lo_response?.guests) {
                setGuestsList(lo_response.guests)
            }else{
                setGuestsList([])
            }
      };
      useEffect(() => {
            getGuestsList();
      }, [ls_selectedEventId]);

      return (<>
            {/* <div className='detailsCardStyle'>
                  <p style={{marginTop:5,color:'white'}}>Guest List for Event:</p>
                  <h3 className='textStylegeustcard'>Test</h3>
                  <p className='textStylegeustcard'>Tuesday,07 November 2023 - 22:44</p>
            </div> */}
            
            <div>
                  <HeaderDetails/>
                  {ls_selectedEventId ? 
                  <div>
                        {/* <div style={{backgroundColor:'#55b18b',padding:10}}>
                              <SearchOutlined style={{fontSize:24,marginInlineStart:5}}/>
                        </div> */}
                        <Spin spinning={lb_spin}>
                              <Row>
                                    {     la_guestsList?.length ?
                                          la_guestsList?.map((rowValue,inx) => {
                                                return (<Col span={24} key={rowValue.id+'-'+inx}>
                                                            <div className={`guestdetailsCardStyle ${rowValue?.attendee ? 'checkinstyle' : ''}`}>
                                                                  <Row justify={'space-between'}>
                                                                        <h3 className='textStylegeustDetailscard'>{rowValue?.billing_first_name} {rowValue?.billing_last_name}</h3>
                                                                        <p className='textStylegeustDetailscard'>Qty : {rowValue?.total_quantity}</p>
                                                                  </Row>
                                                                  
                                                                  <p className='textStylegeustDetailscard'><BookOutlined />  {rowValue?.unique_code}</p>
                                                                  <p className='textStylegeustDetailscard'><MailOutlined />  {rowValue?.billing_email}</p>
                                                            </div>
                                                      </Col>)
                                          })

                                          : <></>
                                          
                                    }
                              </Row>
                        </Spin>
                        {(!la_guestsList?.length && !lb_spin) ? <Row>
                              <Col span={24} style={{marginTop:30}}>
                                    <Empty/>
                              </Col>
                        </Row> : <></>}
                  </div>
                  :
                  <div className='mainView2' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <h2 style={{ color: '#44896c' }}>Please Choose Event to see Checkin user</h2>
                        {/* <UsergroupAddOutlined style={{fontSize:120,color:'#44896c'}} /> */}
                        <Link to={"/event"}>
                              <Button style={{ color: '#44896c' }} size='large' type='default' icon={<ArrowRightOutlined />}>Choose</Button>
                        </Link>
                  </div>
                  }
            </div>
      </>);
};

export default GuestList;
