import React, { useState } from 'react';
import './style.css';
import { CalendarOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

const HeaderDetails = () => {
  const ls_selectedEventId = useSelector( (state) => state.common.selected_event_id );
  const lo_selectedEventDetails = useSelector( (state) => state.common.selected_event_details );
  const lo_userDetails = useSelector( (state) => state.common.user );

  return (
    <>
      <div>
        <div className='checkinCard'>
          <h3 style={{ marginTop: 0, marginBottom: 0, color: 'white' }}>Check In Event Manager {lo_userDetails?.name ? '( '+lo_userDetails?.name+' )' : ''}</h3>
        </div>
        <div className='checkinCard' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <h4 style={{ marginTop: 0, marginBottom: 0, color: 'white' }}>Selected Event:</h4>
          <h4 style={{ marginTop: 0, marginBottom: 0, color: 'white' }}>{ls_selectedEventId ? lo_selectedEventDetails.name:'No Event Selected'}</h4>
        </div>
        {ls_selectedEventId ? <div className='checkinCardSec'>
          <CalendarOutlined style={{marginTop: -28, marginRight:4}} className='textStylegeustcardSec' /><h5 className='textStylegeustcardSec'>
            {lo_selectedEventDetails.starts_date ? ` ${dayjs(lo_selectedEventDetails.starts_date, "YYYY-MM-DD").format("D MMM, ddd YYYY |")}` : ""}
            {lo_selectedEventDetails.starts_time ? ` ${dayjs(lo_selectedEventDetails.starts_time, "HH:mm:ss").format("h:mm A")}` : ""}
            {lo_selectedEventDetails.ends_time ? ` - ${dayjs(lo_selectedEventDetails.ends_time, "HH:mm:ss").format("h:mm A")}` : ""}
          </h5>
        </div> : <></>}
        {/* <div className='mainView' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <h2 style={{ marginBottom: 20, color: '#44896c' }}>Check In</h2>
          <ScanOutlined style={{fontSize:120,color:'#44896c'}} />
          <h2  style={{ marginTop: 20, color: '#44896c' }}>Tap to Scan</h2>
        </div> */}
      </div>
    </>
  );
};

export default HeaderDetails;
