import React, { useState } from 'react';
import './style.css';
import { ArrowRightOutlined, CalendarOutlined, ScanOutlined } from '@ant-design/icons';
import Html5QrcodeScannerPlugin from '../../components/QrcodeScannerPlugin';
import { message } from 'antd';
import { useSelector } from 'react-redux';
import { getQRCodeUniqueId } from '../../utility';
import { getRequestApi, postRequestApi } from '../../../api/commonRequest';
import { Link } from 'react-router-dom';
import { Button, Result, Spin } from 'antd/lib';
import ScannedDetails from './ScannedDetails';
import HeaderDetails from '../../components/HeaderDetails';

const startLoadTheMessage = () => message.loading({ content: 'Loading...', key: 'load', duration: 0 })
const stopLoadingMsh =(msg) => message.error({ content: msg, key: 'load', duration: 2, })
const stopLoadingSuccessMsh =(msg) => message.success({ content: msg, key: 'load', duration: 2, });

const Scan = () => {
  const ls_selectedEventId = useSelector( (state) => state.common.selected_event_id );
  const lo_selectedEventDetails = useSelector( (state) => state.common.selected_event_details );
  const lo_userDetails = useSelector( (state) => state.common.user );
  const [lo_qrCodeDetails, setQrCodeDetails] = useState(null);
  const [lb_spin, setSping] = useState(false);
  const onNewScanResult = (decodedText, decodedResult) => {
    setSping(true)
    startLoadTheMessage()
    console.log('decodedText, decodedResult',decodedText, decodedResult);
    // handle decoded results here
    let qrId = getQRCodeUniqueId(decodedText);
    if (qrId && ls_selectedEventId) {
      console.log('===qrId',qrId);
      getAndSetTicketQRCodeDetails(qrId);
    }else{
      stopLoadingMsh("Not valid!")
      setSping(false)
    }
  };
  const getAndSetTicketQRCodeDetails = async (qrId) => {
    const lo_paramData = {
      "unique_code":qrId,
      "selected_event_id":ls_selectedEventId
    }
    const lo_response = await postRequestApi("user-attend-event",lo_paramData);
    console.log('====lo_response',lo_response);
    setQrCodeDetails(lo_response);
    stopLoadingSuccessMsh("Scanned Successfully!");
    setSping(false)
  }
  const onScanCancel = (value) => {
    console.log('onScanCancel',value);
  }
  return (
    <>
      <div>
        <HeaderDetails/>
        {/* <div className='mainView' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <h2 style={{ marginBottom: 20, color: '#44896c' }}>Check In</h2>
          <ScanOutlined style={{fontSize:120,color:'#44896c'}} />
          <h2  style={{ marginTop: 20, color: '#44896c' }}>Tap to Scan</h2>
        </div> */}
      </div>

      {lo_qrCodeDetails ?
        <ScannedDetails lo_qrCodeDetails={lo_qrCodeDetails} setQrCodeDetails={setQrCodeDetails}/>
        :
        <Spin spinning={lb_spin}>
        <div>
          {ls_selectedEventId ? 
            <div className='mainView' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <h2 style={{ marginBottom: 20, color: '#44896c' }}>Check In</h2>
              <Html5QrcodeScannerPlugin
                fps={10}
                qrbox={250}
                disableFlip={false}
                qrCodeSuccessCallback={onNewScanResult}
                qrCodeErrorCallback={onScanCancel}
              />
              <h2  style={{ marginTop: 20, color: '#44896c' }}>Tap to Scan</h2>
            </div>
            :
            <div className='mainView2' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <h2 style={{ marginBottom: 20, color: '#44896c' }}>Please Choose Event to Scan</h2>
              <ScanOutlined style={{fontSize:120,color:'#44896c'}} />
              <Link to={"/event"}>
                <Button style={{ color: '#44896c' }} size='large' type='default' icon={<ArrowRightOutlined />}>Choose</Button>
              </Link>
            </div>
          }
        </div>
        </Spin>
      }

      

      

    </>
  );
};

export default Scan;
