import React, { useEffect } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';

const Html5QrcodeScannerPlugin = (props) => {
    useEffect(() => {
        // when component mounts
        const config = {
            fps: props.fps,
            qrbox: props.qrbox,
            aspectRatio: props.aspectRatio,
            disableFlip: props.disableFlip,
        };
        const verbose = props.verbose === true;
        // Suceess callback is required.
        if (!(props.qrCodeSuccessCallback)) {
            throw "qrCodeSuccessCallback is required callback.";
        }
        const html5QrcodeScanner = new Html5QrcodeScanner(
            "html5qr-code-full-region",
            config,
            verbose
        );
        html5QrcodeScanner.render(
            props.qrCodeSuccessCallback,
            props.qrCodeErrorCallback
        );
        // cleanup function when component will unmount
        return () => {
            html5QrcodeScanner.clear().catch((error) => {
                console.error("Failed to clear html5QrcodeScanner. ", error);
            });
        };
    }, []);

    return <div id="html5qr-code-full-region" />;
};

export default Html5QrcodeScannerPlugin;