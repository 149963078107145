import Filter from 'bad-words';
import { Api_base_url } from '../../config';
import dayjs from 'dayjs';
import store from "../store";
import { logout } from '../store/common';

export const clearAll = () => { // it will be called when user loged out;
    store.dispatch(logout())
}

export const formatName = (ls_name = '') => {
    if (ls_name === '')
        return ls_name;

    else {
        let la_name = ls_name.split(" ");
        if (la_name[1] === null)
            return la_name[0][0].toUpperCase();

        return la_name[0][0].toUpperCase() + ' ' + la_name[1][0].toUpperCase();
    }
}

export const getDate = () => {
    return new Date();
}
export const capitalizeFirstLetter = (string) => {
    let ls_removeSpecialChar = string.replace('_', ' ');
    return ls_removeSpecialChar.split(" ").map(function (w) { return w[0].toUpperCase() + w.substr(1) }).join(" ").replace('_', ' ');
}

export const actionForUserLogout = () => {
    store.dispatch(logout())
}
export const objectToFormData = (obj) => {
    const formData = new FormData();
  
    Object.entries(obj).forEach(([key, value]) => {
      formData.append(key, value);
    });
  
    return formData;
}

export const checkProfanity = (text) => {
    const filter = new Filter();
    return filter.isProfane(text);
};

export const isExistProfanity = async (la_text) => {
    const la_profinity = []
    for (let k = 0; k < la_text.length; k++) {
        const value = la_text[k];
        if (checkProfanity(value)) {
            la_profinity.push(value)
        }
    }
    return la_profinity.length;
};

export const getAndsetAllOrganizationList = (ls_token) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+ls_token);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

    fetch(Api_base_url+"all-organisations", requestOptions)
    .then(response => response.text())
    .then(result => {
        const lo_response = JSON.parse(result)
    })
    .catch(error => console.log('error', error));
  }

  const getDateTimeWithSpecificFormat = (lo_dateTime) => {
        return `${dayjs(lo_dateTime, "YYYY-MM-DD HH:mm:ss").format("dddd, MMMM D, YYYY on h:mm A")}`;
  }

export const getDeviceType = () => {
    const li_width = window.screen.width;
    if (li_width < 576)
        return 'xs';
    else
        return 'sm';
}

export const stringAvatar = (name) => {
    if (name) {
        return {
            children:
              name.split(" ")[1] == null
                ? `${name.split(" ")[0][0]}`
                : `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
          };
    } else return {}
};
export const getCountryIdByCountryName=(ls_countryName, la_countryList)=>{
    const result =  la_countryList.find((element) => element.name.toLowerCase() === ls_countryName.toLowerCase());
    return result?.id ? result.id : null;
}
export const getQRCodeUniqueId = (decodedText) => {
    let qrId;
    if (decodedText) {
      const la_decodedText = decodedText.split("\n");
      if (la_decodedText?.length > 0) {
        if (la_decodedText[0]) {
          const la_decodedTextNew =  la_decodedText[0].split(": ");
          if (la_decodedTextNew?.length > 1) {
            qrId = la_decodedTextNew[1];
          }
        }
      }
    }
    return qrId;
}

export const convertObjectToArray = (obj) => {
    let arr = [];
    if (obj) {
        arr = Object.values(obj);
    }
    return arr;
}

