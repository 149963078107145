import React, { useState } from 'react';
import { Button, Result } from 'antd/lib';
import { ScanOutlined } from '@ant-design/icons';

const ScannedDetails = ({lo_qrCodeDetails, setQrCodeDetails}) => {
  return (
    <>
      <div className='mainView' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <h2 style={{ marginBottom: 20, color: '#44896c' }}>Ticket Details</h2>
        {lo_qrCodeDetails?.status == 'success' ? <Result
          status="success"
          title={lo_qrCodeDetails?.message}
          subTitle={lo_qrCodeDetails?.qrcode}
        />: <></>}
        {lo_qrCodeDetails?.status == 'error' ? <Result
          status="warning"
          title={lo_qrCodeDetails?.message}
          subTitle={lo_qrCodeDetails?.qrcode}
        />: <></>}

        <Button onClick={() => setQrCodeDetails(null)} size='middle' type='default' icon={<ScanOutlined />}>New Scan</Button>
      </div>
    </>
  );
};

export default ScannedDetails;
